import React from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { createNoteAction, deleteNoteAction, updateNoteAction } from '../../actions/note';
import { GET_NOTE } from '../../actions/types';
import { Textarea } from '../../lib/HooksFormFields';
import { getWeekNumber } from '../../utils';
import styles from './NoteModal.module.scss';

const NoteModal = () => {
  const dispatch = useDispatch();
  const { note } = useSelector((d: any) => d.notesReducer);
  const weekNumber = note?.endDate && getWeekNumber(new Date(note?.endDate));
  const { control, handleSubmit } = useForm<FieldValues>({
    defaultValues: note,
  });

  function onSubmit(values: FieldValues) {
    const data = {
      ...values,
      user: note?.user?._id,
    };
    if (note?._id) {
      return updateNoteAction(dispatch, data, note?._id);
    }
    return createNoteAction(dispatch, data);
  }

  async function deleteNote() {
    await deleteNoteAction(dispatch, note._id);
    return dispatch({
      type: GET_NOTE,
      payload: null,
    });
  }

  return (<div className={styles.container}>
    <h4>
      Ajouter une note à
      {' '}
      {note?.user?.profile?.firstName} {note?.user?.profile?.lastName}
      {' '}
      sur la semaine {weekNumber || null}
    </h4>
    <div className={styles.text}>
      <Textarea
        control={control}
        name="text"
        rules={{ }}
      />

    </div>
    <div className={styles.actions}>
      {note?._id
        ? <button
          onClick={() => deleteNote()}
          className={styles.warn}
        >
          Supprimer
        </button>
        : <div/>
      }
      <button
        onClick={handleSubmit(onSubmit)}
      >
        Valider
      </button>

    </div>

  </div>
  );
};

export default NoteModal;
