import React, { useEffect, useMemo, useState } from 'react';
import { useForm, get, FieldValues } from 'react-hook-form';
import { saveAs } from 'file-saver';
import { useSelector, useDispatch } from 'react-redux';
import { format } from 'date-fns';
import { exportXlsxAction, getExportLists } from '../../actions/export';
import { getAllStructuresAction } from '../../actions/structures';
import { ErrorField, StyledSelect } from '../../lib/HooksFormFields';
import { IStructure } from '../../types/structure';
import styles from './ExportAdmin.module.scss';
import { generateMonthsObjects } from '../../utils';

const exportTypes = [
  {
    value: 'annualization',
    label: 'Annualisation',
  },
  {
    value: 'common-pay-elements',
    label: 'Elements de paie commun',
  },
  {
    value: 'monthly-hours',
    label: 'Suivi heures',
  },
];

const ExportAdmin = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [apiError, setApiError] = useState<string | null>(null);
  const userAuth = useSelector((store: any) => store.authReducer)?.user;
  const { structuresList } = useSelector((store: any) => store.structureReducer);
  const {
    list,
    exportList,
  } = useSelector((store: any) => store.shiftsReducer);

  const {
    control,
    reset,
    setValue,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<FieldValues>();

  const form = watch();
  const structuresOptions = structuresList?.map(
    (s: IStructure) => ({ value: s._id, label: s.name }),
  );

  const options = useMemo(() => {
    const opt : any = {
      users: [],
      poles: [],
      startDate: [{ value: null, label: 'Séléctionner' }],
      endDate: [{ value: null, label: 'Séléctionner' }],
    };
    if (form.structure && exportList?.users) {
      opt.users = exportList?.users.filter((u: any) => u.structure === form.structure);
      const poles = exportList?.structures.find((u: any) => u.value === form.structure)?.poles;
      if (poles.length) {
        opt.poles = [...poles];
      }
      if (form.period) {
        const months = generateMonthsObjects(form.period.startDate, form.period.endDate);
        months.forEach((m:any) => {
          opt.startDate.push({ value: m.start, label: m.month });
          opt.endDate.push({ value: m.end, label: m.month });
        });
        const today = new Date();
        const current = months.findIndex(
          (m) => new Date(m.start).getMonth() === today.getMonth(),
        );
        const prev = months.findIndex(
          (m) => new Date(m.start).getMonth() === today.getMonth() - 1,
        );
        console.log(prev);
        if (current !== -1 && !form?.endDate) {
          setValue('endDate', months[current]?.end);
        }
        if (prev !== -1 && !form?.startDate) {
          console.log(prev);
          setValue('startDate', months[prev]?.start);
        } else {
          setValue('startDate', months[current]?.start);
        }
      }
      if (form.startDate) {
        opt.endDate = [{ value: null, label: 'Séléctionner' }, ...opt.endDate.filter((w: any) => new Date(w.value) > new Date(form.startDate))];
      }
    }
    return opt;
  }, [exportList, form.structure, form.period, form.startDate]);

  async function onSubmit(values: FieldValues) {
    setIsLoading(true);
    setApiError(null);
    const startDate = format(new Date(values.startDate), 'yyyy-MM-dd');
    const endDate = format(new Date(values.endDate), 'yyyy-MM-dd');
    const data = {
      type: values.type,
      startDate,
      endDate,
      structure: values.structure,
    };
    try {
      const res : any = await exportXlsxAction(dispatch, data);

      if (res) {
        const blob = new Blob([res]);
        const type = exportTypes?.find((t: any) => t.value === values.type)?.label;
        const structure = structuresOptions?.find((t: any) => t.value === values.structure)?.label;
        const filename = `${type}-${structure}-${startDate}-${endDate}.xlsx`;
        // Use file-saver to save the blob
        saveAs(blob, filename);
      }
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      setIsLoading(false);
      setApiError('Une erreur c\'est produite lors de l\'export');
    }
  }

  useEffect(() => {
    getAllStructuresAction(dispatch);
    getExportLists(dispatch);
  }, []);

  useEffect(() => {
    const foundPeriod = list?.periods?.find((p:any) => p.value?.isCurrent);
    if (foundPeriod) {
      reset({
        type: 'annualization',
        structure: localStorage.getItem('structure'),
        period: foundPeriod?.value,
      });
    }
  }, [list?.periods]);

  return (
    <div className={styles.modale}>
      <div className={styles.types}>
        {exportTypes.map((t) => (
          <button
            key={t.value}
            type='button'
            className={form?.type === t.value ? styles.active : ''}
            onClick={() => setValue('type', t.value)}
          >
            {t.label}
          </button>
        ))}
      </div>
      {typeof get(errors, 'type')?.message === 'string' && (
        <ErrorField message={get(errors, 'type')?.message || ''}/>
      )}
      <div className={styles.form}>
        <div className={styles.row}>
          {(userAuth.role === 'Admin' || userAuth.role === 'Owner')
            && <div className={styles['container-field']}>
              <StyledSelect
                  name="structure"
                  control={control}
                  options={structuresOptions}
                  rules={{
                    required: 'la structure est requise',
                  }}
              />
              {typeof get(errors, 'structure')?.message === 'string' && (
                <ErrorField message={get(errors, 'structure')?.message || ''}/>
              )}
            </div>
          }
        <div className={styles['container-field']}>
          <StyledSelect
              name="period"
              control={control}
              options={list.periods}
              rules={{
                required: 'la période est requise',
              }}
          />
          {typeof get(errors, 'period')?.message === 'string' && (
            <ErrorField message={get(errors, 'period')?.message || ''}/>
          )}
        </div>
        </div>
      {options.startDate
        && <div className={styles.row}>
          <div className={styles['container-field']}>
            <StyledSelect
                name="startDate"
                label={'Mois de départ'}
                control={control}
                options={options.startDate}
                rules={{
                  required: 'le mois de départ est requis',
                }}
            />
            {typeof get(errors, 'startDate')?.message === 'string' && (
              <ErrorField message={get(errors, 'startDate')?.message || ''}/>
            )}
          </div>
          <div className={styles['container-field']}>
            <StyledSelect
                name="endDate"
                label={'Mois de fin'}
                control={control}
                options={options.endDate}
                rules={{
                  required: 'le mois de départ est requis',
                }}
            />
            {typeof get(errors, 'endDate')?.message === 'string' && (
              <ErrorField message={get(errors, 'endDate')?.message || ''}/>
            )}
          </div>
        </div>
      }
      {apiError && (
        <div className={styles.error}>
          <ErrorField message={apiError}/>
        </div>
      )}
      {!isLoading
        ? <div className={styles.actions}>
          <button
            type="button"
            onClick={handleSubmit(onSubmit)}
          >
            Exporter
          </button>
        </div>
        : <div className={styles.actions}>
          Export en cours...
        </div>
      }
    </div>
    </div>
  );
};

export default ExportAdmin;
