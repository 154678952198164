import React, { useEffect } from 'react';
import { useFormContext, get } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  ErrorField,
  InputColor,
  InputEmail, InputFormatPattern, InputText,
} from '../../lib/HooksFormFields';
import InputMedia from '../../lib/HooksFormFields/InputMedia';
import StyledSelect from '../../lib/HooksFormFields/StyledSelect';
import SelectTags from '../../lib/HooksFormFields/SelectTags';
import { IStructure, IPole } from '../../types/structure';
import styles from './User.module.scss';

export const contractOptions = ['CDI', 'CDD'].map((d) => ({ label: d, value: d }));

export const rolesOptions = [
  {
    value: 'Member',
    label: 'Collaborateur',
    color: '#1C376B',
  },
  {
    value: 'Manager',
    label: 'Gestionnaire',
    color: '#1C376B',
  },
];

export const rolesAdminOptions = [
  {
    value: 'Member',
    label: 'Collaborateur',
    color: '#1C376B',
  },
  {
    value: 'Manager',
    label: 'Gestionnaire',
    color: '#1C376B',
  },
  {
    value: 'Owner',
    label: 'Oiikos',
    color: '#1C376B',
  },
  {
    value: 'Admin',
    label: 'Administrateur',
    color: '#1C376B',
  },
];

const UserForm = ({ onSubmit, isProfile = false }: { onSubmit: any, isProfile?: boolean }) => {
  const {
    watch, control, formState: { errors }, reset, setValue,
  } = useFormContext<any>();
  const { structuresList } = useSelector((store: any) => store.structureReducer);
  const { user } = useSelector((store: any) => store.authReducer);
  const isAdmin = user?.role === 'Admin';
  const structuresOptions = structuresList
    ?.map((s :IStructure) => ({ value: s._id, label: s.name }));
  const form = watch();
  const structure = structuresList?.find((s: IStructure) => s._id === form.structure);
  const poles = structure?.poles
    ?.map((p: IPole) => ({ label: p.name, color: p.color, value: p._id }));

  useEffect(() => {
    if (form?.role === 'Admin') {
      reset({ ...form, structure: null, poles: [] });
    }
  }, [form?.role]);
  return (
    <div className={styles.container}>
      <div className={styles.bg}/>
      <div className={styles.content}>
        <div className={styles.avatar}>
          <InputMedia
            name="picture"
            media={form?.picture}
            handleChange={onSubmit}
            submit={onSubmit}
            disabled={!form._id}
          />
          <div className={styles.name}>
            <h1>{form?.profile?.firstName}
            {' '}
            {form?.profile?.lastName}</h1>
            {structure?.name
            && <p>{structure.name}</p>}
          </div>
        </div>
        <div className={styles.form}>
          {!isProfile
            && <div className={styles.links}>
              <div className={styles.link}>
                Informations
              </div>
              {(form?.role === 'Member')
                && <Link to={`/admin/user/contract/${form?._id}`} className={styles.link}>
                  Contrats
                </Link>
              }
            </div>
          }
          <div className={`${styles.profile} ${isProfile ? styles.isProfile : ''}`}>
            <div className={styles.col}>
              <div className={styles['container-field']}>
                <InputText
                  name="profile.firstName"
                  control={control}
                  label="Prénom"
                  rules={{
                    required: 'Veuillez renseigner le prénom',
                  }}
                />
              {typeof get(errors, 'profile.firstName')?.message === 'string' && (
                <ErrorField message={get(errors, 'profile.firstName')?.message || ''}/>
              )}
              </div>
            </div>
            <div className={styles.col}>
              <div className={styles['container-field']}>
                <InputText
                  name="profile.lastName"
                  control={control}
                  label="Nom"
                  rules={{
                    required: 'Veuillez renseigner le nom',
                  }}
                />
                {typeof get(errors, 'profile.lastName')?.message === 'string' && (
                  <ErrorField message={get(errors, 'profile.lastName')?.message || ''}/>
                )}
              </div>
            </div>
            {(form?.role === 'Member' || form.role === 'Manager' || form.role === 'Owner') && !isProfile && isAdmin
            && <div className={styles.col}>
              <div className={styles['container-field']}>
                <StyledSelect
                  name="structure"
                  control={control}
                  label="Site"
                  options={structuresOptions}
                  rules={{
                    required: 'Veuillez renseigner le site',
                  }}
                />
                {typeof get(errors, 'structure')?.message === 'string' && (
                  <ErrorField message={get(errors, 'structure')?.message || ''}/>
                )}
              </div>
            </div>
            }
          </div>
          <div className={`${styles.infos} ${isProfile ? styles.isProfile : ''}`}>
            <div className={styles.col}>
              <h2>Infos personnelles</h2>
              {!isProfile
                && <div className={styles['container-field']}>
                  <InputText
                    name="code"
                    control={control}
                    label="Matricule"
                    rules={{}}
                  />
                  {typeof get(errors, 'code')?.message === 'string' && (
                  <ErrorField message={get(errors, 'code')?.message || ''}/>
                  )}
                </div>
              }
              {!isProfile
                && <div className={styles['container-field']}>
                  <StyledSelect
                    name="isActive"
                    control={control}
                    label="Status de l'utilisateur"
                    options={[
                      { label: 'Utilisateur Actif', value: true },
                      { label: 'Utilisateur Archivé', value: false },
                    ]}
                  />
                </div>
              }
              <div className={styles['container-field']}>
                <InputFormatPattern
                  name="phone"
                  label="Téléphone"
                  control={control}
                  format="## ## ## ## ##"
                />
                {typeof get(errors, 'phone')?.message === 'string' && (
                <ErrorField message={get(errors, 'phone')?.message || ''}/>
                )}
              </div>
              <div className={styles['container-field']}>
                <InputEmail
                  name="email"
                  control={control}
                  label="Email"
                  required
                />
                {typeof get(errors, 'email')?.message === 'string' && (
                <ErrorField message={get(errors, 'email')?.message || ''}/>
                )}
              </div>
              {!isProfile
                && <div className={styles['container-field']}>
                  <label>Couleur dans le planning</label>
                  <div className={styles.color}>
                    <InputColor
                      name="color"
                      control={control}
                      className={'small'}
                      rules={{
                      }}
                    />
                  </div>
                  <p className={styles['reset-color']} onClick={() => setValue('color', '#FFFFFF')}>Couleur par défault</p>
                </div>
              }
            </div>
             {(!isProfile && user._id !== form?._id)
               ? <div className={styles.roles}>
              <h2>Type de profil</h2>
              <SelectTags
                name="role"
                control={control}
                options={user.role === 'Admin' ? rolesAdminOptions : rolesOptions}
                defaultValue="Member"
                rules={{
                  required: 'Veuillez renseigner le rôle',
                }}
              />
              {typeof get(errors, 'role')?.message === 'string' && (
                  <ErrorField message={get(errors, 'role')?.message || ''}/>
              )}
            </div>
               : <div className={styles.roles}>
              </div>
          }
            {!isProfile && (form?.role === 'Member' || form.role === 'Manager')
                && <>
              <div className={styles.col}>
                <h2>Catégories associés</h2>
                {poles
                  && <SelectTags
                    name="poles"
                    control={control}
                    options={poles}
                    isMulti={true}
                    defaultValue={[]}
                    rules={{
                    }}
                  />
                }
              </div>
            </>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserForm;
